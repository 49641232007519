import React from 'react';
import LightingList from '../components/lightings/LightingList';

const LightingPage = () => {
    return (
        <div>
            <h1>Accessories</h1>
            <LightingList />
        </div>
    );
};

export default LightingPage;

import React from 'react';
import CameraList from "../components/cameras/CameraList";

const CameraPage = () => {
    return (
        <div>
            <h1>Cameras</h1>
            <CameraList />
        </div>
    );
};

export default CameraPage;

import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import ItemCard from '../../ItemCard';
import { getAllAudioEquipments } from '../../services/audioEquipmentService';

const AudioEquipmentList = () => {
    const [audioEquipments, setAudioEquipments] = useState([]);

    useEffect(() => {
        async function fetchAudioEquipments() {
            try {
                const data = await getAllAudioEquipments();
                setAudioEquipments(data);
            } catch (error) {
                console.error('Error fetching audio equipment:', error);
            }
        }
        fetchAudioEquipments();
    }, []);

    return (
        <div>
            <h1>Audio Equipment List</h1>
            <div className="item-list">
                {audioEquipments.map((audioEquipment) => (
                    <Link to={`/audioequipments/${audioEquipment.id}`} key={audioEquipment.id}>
                        <ItemCard item={audioEquipment} />
                    </Link>
                ))}
            </div>
        </div>
    );
};

export default AudioEquipmentList;

import axios from 'axios';

const API_URL = process.env.REACT_APP_API_URL || 'https://rent-ranger-e74d7d998006.herokuapp.com/api/useditems';

export const getAllUsedItems = async () => {
    const response = await axios.get(API_URL);
    return response.data;
};

export const getUsedItemById = async (id) => {
    const response = await axios.get(`${API_URL}/${id}`);
    return response.data;
};

export const addUsedItem = async (usedItem) => {
    const response = await axios.post(API_URL, usedItem);
    return response.data;
};

export const updateUsedItem = async (id, usedItem) => {
    const response = await axios.put(`${API_URL}/${id}`, usedItem);
    return response.data;
};

export const deleteUsedItem = async (id) => {
    const response = await axios.delete(`${API_URL}/${id}`);
    return response.data;
};

export const getAvailableUsedItems = async () => {
    const response = await axios.get(`${API_URL}/available`);
    return response.data;
};

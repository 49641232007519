import axios from 'axios';

const api = axios.create({
    baseURL: process.env.REACT_APP_API_URL, // Use environment variable for base URL
    timeout: 10000,
});

api.interceptors.request.use(config => {
    return config;
}, error => {
    return Promise.reject(error);
});

api.interceptors.request.use((config) => {
    const token = localStorage.getItem('token');
    if (token) {
        config.headers.Authorization = `Bearer ${token}`;
    }
    return config;
}, (error) => {
    return Promise.reject(error);
});

api.interceptors.response.use(response => {
    return response;
}, error => {
    console.error('API error:', error);
    return Promise.reject(error);
});

export default api;

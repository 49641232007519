import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import ItemCard from '../../ItemCard';
import { getAllUsedItems } from '../../services/usedItemService';

const UsedItemList = () => {
    const [usedItems, setUsedItems] = useState([]);

    useEffect(() => {
        async function fetchUsedItems() {
            try {
                const data = await getAllUsedItems();
                setUsedItems(data);
            } catch (error) {
                console.error('Error fetching used items:', error);
            }
        }
        fetchUsedItems();
    }, []);

    return (
        <div>
            <h1>Used Item List</h1>
            <div className="item-list">
                {usedItems.map((usedItem) => (
                    <Link to={`/useditems/${usedItem.id}`} key={usedItem.id}>
                        <ItemCard item={usedItem} />
                    </Link>
                ))}
            </div>
        </div>
    );
};

export default UsedItemList;

import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import ItemCard from '../../ItemCard';
import api from '../../api';

const AccessoryList = () => {
    const [accessories, setAccessories] = useState([]);

    useEffect(() => {
        const fetchAccessories = async () => {
            try {
                const response = await api.get('/accessories');
                setAccessories(response.data);
            } catch (error) {
                console.error('Failed to fetch accessories', error);
            }
        };
        fetchAccessories();
    }, []);

    return (
        <div>
            <h2>Accessories</h2>
            <div className="item-list">
                {accessories.map((accessories) => (
                    <Link to={`/accessories/${accessories.id}`} key={accessories.id}>
                        <ItemCard item={accessories} />
                    </Link>
                ))}
            </div>
        </div>
    );
};

export default AccessoryList;
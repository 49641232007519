import React from 'react';
import LensList from "../components/lenses/LensList";

const LensPage = () => {
    return (
        <div>
            <h1>Lenses</h1>
            <LensList />
        </div>
    );
};

export default LensPage;

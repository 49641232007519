import React from 'react';

const ItemCard = ({ item }) => {
    return (
        <div className="item-card">
            <img src={item.imageUrl} alt={item.name} />
            <h3>{item.name}</h3>
            <p>{item.description}</p>
            <p>${item.pricePerDay} per day</p>
            <button>Add to Cart</button>
        </div>
    );
};

export default ItemCard;

import React from 'react';
import AccessoryList from '../components/accessories/AccessoryList';

const AccessoryPage = () => {
    return (
        <div>
            <h1>Accessories</h1>
            <AccessoryList />
        </div>
    );
};

export default AccessoryPage;

import React, { createContext, useState, useEffect } from 'react';
import { authenticateUser } from '../services/authService';

const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
    const [user, setUser] = useState(null);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        const token = localStorage.getItem('token');
        if (token) {
            try {
                const user = parseJwt(token);
                const tokenExpiration = user.exp * 1000;
                if (tokenExpiration > Date.now()) {
                    setUser(user);
                    // Optionally, refresh the token if it's about to expire
                    if (tokenExpiration - Date.now() < 600000) { // 10 minutes before expiration
                        // Call a token refresh endpoint if available
                    }
                } else {
                    localStorage.removeItem('token');
                }
            } catch (error) {
                console.error('Invalid token', error);
                localStorage.removeItem('token');
            }
        }
        setLoading(false);
    }, []);

    const login = async (credentials) => {
        const { token } = await authenticateUser(credentials);
        localStorage.setItem('token', token);
        const user = parseJwt(token);
        setUser(user);
    };

    const logout = () => {
        localStorage.removeItem('token');
        setUser(null);
    };

    const parseJwt = (token) => {
        try {
            return JSON.parse(atob(token.split('.')[1]));
        } catch (e) {
            return null;
        }
    };

    return (
        <AuthContext.Provider value={{ user, loading, login, logout }}>
            {children}
        </AuthContext.Provider>
    );
};

export default AuthContext;
import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import ItemCard from '../../ItemCard';
import { getAllCameras } from '../../services/cameraService';

const CameraList = () => {
    const [cameras, setCameras] = useState([]);

    useEffect(() => {
        async function fetchCameras() {
            try {
                const data = await getAllCameras();
                setCameras(data);
            } catch (error) {
                console.error('Error fetching cameras:', error);
            }
        }
        fetchCameras();
    }, []);

    return (
        <div>
            <h1>Camera List</h1>
            <div className="item-list">
                {cameras.map((camera) => (
                    <Link to={`/cameras/${camera.id}`} key={camera.id}>
                        <ItemCard item={camera} />
                    </Link>
                ))}
            </div>
        </div>
    );
};

export default CameraList;

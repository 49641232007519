import React, { useEffect, useRef } from 'react';
import $ from 'jquery';
import 'bootstrap-daterangepicker/daterangepicker.css';

const DateRangePicker = ({ onDateRangeChange }) => {
    const pickerRef = useRef();

    useEffect(() => {
        $(pickerRef.current).daterangepicker(
            {
                locale: { format: 'YYYY-MM-DD' },
                opens: 'left',
            },
            function (start, end) {
                onDateRangeChange(start.format('YYYY-MM-DD'), end.format('YYYY-MM-DD'));
            }
        );
    }, [onDateRangeChange]);

    return (
        <div className="form-group">
            <label>Select Rental Date Range</label>
            <input
                type="text"
                className="form-control"
                ref={pickerRef}
            />
        </div>
    );
};

export default DateRangePicker;

import React from 'react';
import TripodList from '../components/tripods/TripodList';

const TripodPage = () => {
    return (
        <div>
            <h1>Tripods</h1>
            <TripodList />
        </div>
    );
};

export default TripodPage;

import React, { useState } from 'react';
import { Container, Form, Button, Row, Col, Alert } from 'react-bootstrap';
import api from '../../api';

const Register = () => {
    const [username, setUsername] = useState('');
    const [email, setEmail] = useState('');  // New email state
    const [password, setPassword] = useState('');
    const [message, setMessage] = useState('');

    const handleRegister = async (e) => {
        e.preventDefault();
        try {
            const response = await api.post('/auth/register', { username, email, password });  // Include email in the request
            setMessage(response.data.message);
        } catch (error) {
            if (error.response && error.response.data) {
                setMessage(error.response.data.message);
            } else {
                setMessage('Registration failed');
            }
        }
    };

    return (
        <Container className="mt-5" style={{ maxWidth: '400px' }}>
            <Row className="justify-content-center">
                <Col>
                    <h1 className="text-center mb-4">Sign Up</h1>
                    <Form onSubmit={handleRegister}>
                        <Form.Group className="mb-3" controlId="username">
                            <Form.Label>Username</Form.Label>
                            <Form.Control
                                type="text"
                                placeholder="Enter username"
                                value={username}
                                onChange={(e) => setUsername(e.target.value)}
                                required
                                autoFocus
                            />
                        </Form.Group>

                        <Form.Group className="mb-3" controlId="email">
                            <Form.Label>Email</Form.Label>
                            <Form.Control
                                type="email"
                                placeholder="Enter email"
                                value={email}
                                onChange={(e) => setEmail(e.target.value)}
                                required
                            />
                        </Form.Group>

                        <Form.Group className="mb-3" controlId="password">
                            <Form.Label>Password</Form.Label>
                            <Form.Control
                                type="password"
                                placeholder="Enter password"
                                value={password}
                                onChange={(e) => setPassword(e.target.value)}
                                required
                            />
                        </Form.Group>

                        <Form.Group className="mb-3" controlId="allowExtraEmails">
                            <Form.Check type="checkbox" label="I want to receive inspiration, marketing promotions, and updates via email." />
                        </Form.Group>

                        <Button variant="primary" type="submit" className="w-100">
                            Sign Up
                        </Button>
                    </Form>

                    {message && (
                        <Alert variant={message === 'User registered successfully' ? 'success' : 'danger'} className="mt-3 text-center">
                            {message}
                        </Alert>
                    )}

                    <Row className="mt-3">
                        <Col className="text-center">
                            <a href="/login" className="text-muted">Already have an account? Sign in</a>
                        </Col>
                    </Row>
                </Col>
            </Row>
        </Container>
    );
};

export default Register;
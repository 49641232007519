import React from 'react';
import UsedItemList from '../components/usedItems/UsedItemList';

const UsedItemPage = () => {
    return (
        <div>
            <h1>UsedItems</h1>
            <UsedItemList />
        </div>
    );
};

export default UsedItemPage;

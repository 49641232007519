import React, { useState } from 'react';
import DateRangePicker from '../components/DateRangePicker';

const RentalPage = () => {
    const [startDate, setStartDate] = useState(null);
    const [endDate, setEndDate] = useState(null);

    const handleDateRangeChange = (start, end) => {
        setStartDate(start);
        setEndDate(end);
    };

    return (
        <div>
            <h1>Rent an Item</h1>
            <DateRangePicker onDateRangeChange={handleDateRangeChange} />
            {startDate && endDate && (
                <p>
                    Selected Date Range: {startDate} to {endDate}
                </p>
            )}
        </div>
    );
};

export default RentalPage;

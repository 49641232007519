import axios from 'axios';

const API_URL = process.env.REACT_APP_API_URL || 'https://rent-ranger-e74d7d998006.herokuapp.com/api/cameras';

export const getAllCameras = async () => {
    const response = await axios.get(API_URL);
    return response.data;
};

export const getCameraById = async (id) => {
    const response = await axios.get(`${API_URL}/${id}`);
    return response.data;
};

export const addCamera = async (camera) => {
    const response = await axios.post(API_URL, camera);
    return response.data;
};

export const updateCamera = async (id, camera) => {
    const response = await axios.put(`${API_URL}/${id}`, camera);
    return response.data;
};

export const deleteCamera = async (id) => {
    const response = await axios.delete(`${API_URL}/${id}`);
    return response.data;
};

export const getAvailableCameras = async () => {
    const response = await axios.get(`${API_URL}/available`);
    return response.data;
};

import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Home from './pages/Home';
import Register from './components/auth/Register';
import Login from './components/auth/Login';
import Profile from './pages/Profile';
import AccessoryPage from './pages/AccessoryPage';
import AudioEquipmentPage from './pages/AudioEquipmentPage';
import CameraPage from './pages/CameraPage';
import LensPage from './pages/LensPage';
import LightingPage from './pages/LightingPage';
import ProjectorPage from './pages/ProjectorPage';
import RentalPage from './pages/RentalPage';
import TripodPage from './pages/TripodPage';
import UsedItemPage from './pages/UsedItemPage';
import Footer from './components/common/Footer';
import PrivateRoute from './components/common/PrivateRoute';
import { AuthProvider } from './context/AuthContext';
import Header from './components/common/Header'
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap-daterangepicker/daterangepicker.css';
import 'jquery/dist/jquery.min.js'; // jQuery is a dependency
import 'bootstrap-daterangepicker/daterangepicker.js';
import { Container, Navbar } from 'react-bootstrap';

const App = () => {
    return (
        <AuthProvider>
            <Router>
                <Header />
                <Navbar bg="dark" variant="dark">
                    <Navbar.Brand href="/">Rent Ranger</Navbar.Brand>
                </Navbar>
                <Container>
                    <Routes>
                        <Route path="/" element={<Home />} />
                        <Route path="/register" element={<Register />} />
                        <Route path="/login" element={<Login />} />
                        <Route path="/profile" element={<PrivateRoute><Profile /></PrivateRoute>} />
                        <Route path="/accessories" element={<PrivateRoute><AccessoryPage /></PrivateRoute>} />
                        <Route path="/audioequipments" element={<PrivateRoute><AudioEquipmentPage /></PrivateRoute>} />
                        <Route path="/cameras" element={<PrivateRoute><CameraPage /></PrivateRoute>} />
                        <Route path="/lenses" element={<PrivateRoute><LensPage /></PrivateRoute>} />
                        <Route path="/lightings" element={<PrivateRoute><LightingPage /></PrivateRoute>} />
                        <Route path="/projectors" element={<PrivateRoute><ProjectorPage /></PrivateRoute>} />
                        <Route path="/rentals" element={<PrivateRoute><RentalPage /></PrivateRoute>} />
                        <Route path="/tripods" element={<PrivateRoute><TripodPage /></PrivateRoute>} />
                        <Route path="/useditems" element={<PrivateRoute><UsedItemPage /></PrivateRoute>} />
                        <Route path="/rentalpage" element={<PrivateRoute><RentalPage /></PrivateRoute>} />
                    </Routes>
                </Container>
                <Footer className="bg-dark text-white text-center py-3">© 2024 Rent Ranger</Footer>
            </Router>
        </AuthProvider>
    );
};

export default App;
import React from 'react';
import AudioEquipmentList from "../components/audioEquipments/AudioEquipmentList";

const AudioEquipmentPage = () => {
    return (
        <div>
            <h1>AudioEquipment</h1>
            <AudioEquipmentList />
        </div>
    );
};

export default AudioEquipmentPage;

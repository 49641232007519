import axios from 'axios';

const API_URL = process.env.REACT_APP_API_URL || 'https://rent-ranger-e74d7d998006.herokuapp.com/api/lenses';

export const getAllLenses = async () => {
    const response = await axios.get(API_URL);
    return response.data;
};

export const getLensById = async (id) => {
    const response = await axios.get(`${API_URL}/${id}`);
    return response.data;
};

export const addLens = async (lens) => {
    const response = await axios.post(API_URL, lens);
    return response.data;
};

export const updateLens = async (id, lens) => {
    const response = await axios.put(`${API_URL}/${id}`, lens);
    return response.data;
};

export const deleteLens = async (id) => {
    const response = await axios.delete(`${API_URL}/${id}`);
    return response.data;
};

export const getAvailableLenses = async () => {
    const response = await axios.get(`${API_URL}/available`);
    return response.data;
};

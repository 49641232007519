import React, { useState } from 'react';
import { Container, Form, Button, Row, Col, Alert, InputGroup } from 'react-bootstrap';
import { FaEye, FaEyeSlash } from 'react-icons/fa';
import api from '../../api';

const Login = () => {
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const [message, setMessage] = useState('');
    const [showPassword, setShowPassword] = useState(false);

    const handleLogin = async (e) => {
        e.preventDefault();
        try {
            const response = await api.post('/auth/login', { username, password });
            const token = response.data.token;
            localStorage.setItem('token', token); // Store token
            setMessage('Login successful');
            // Redirect user to a protected page, for example, /dashboard
            window.location.href = '/dashboard';
        } catch (error) {
            if (error.response && error.response.data) {
                setMessage(error.response.data.message);
            } else {
                setMessage('Login failed');
            }
        }
    };

    return (
        <Container className="mt-5" style={{ maxWidth: '400px' }}>
            <Row className="justify-content-center">
                <Col>
                    <h1 className="text-center mb-4">Sign In</h1>
                    <Form onSubmit={handleLogin}>
                        <Form.Group className="mb-3" controlId="username">
                            <Form.Label>Username</Form.Label>
                            <Form.Control
                                type="text"
                                placeholder="Enter username"
                                value={username}
                                onChange={(e) => setUsername(e.target.value)}
                                required
                                autoFocus
                            />
                        </Form.Group>

                        <Form.Group className="mb-3" controlId="password">
                            <Form.Label>Password</Form.Label>
                            <InputGroup>
                                <Form.Control
                                    type={showPassword ? 'text' : 'password'}
                                    placeholder="Enter password"
                                    value={password}
                                    onChange={(e) => setPassword(e.target.value)}
                                    required
                                />
                                <Button
                                    variant="outline-secondary"
                                    onClick={() => setShowPassword(!showPassword)}
                                >
                                    {showPassword ? <FaEyeSlash /> : <FaEye />}
                                </Button>
                            </InputGroup>
                        </Form.Group>

                        <Form.Group className="mb-3" controlId="remember">
                            <Form.Check type="checkbox" label="Remember me" />
                        </Form.Group>

                        <Button variant="primary" type="submit" className="w-100">
                            Sign In
                        </Button>
                    </Form>

                    {message && (
                        <Alert variant="danger" className="mt-3 text-center">
                            {message}
                        </Alert>
                    )}

                    <Row className="mt-3">
                        <Col xs="6">
                            <a href="#" className="text-muted">Forgot password?</a>
                        </Col>
                        <Col xs="6" className="text-end">
                            <a href="/register" className="text-muted">Don't have an account? Sign Up</a>
                        </Col>
                    </Row>
                </Col>
            </Row>
        </Container>
    );
};

export default Login;

import axios from 'axios';

const API_URL = process.env.REACT_APP_API_URL || 'https://rent-ranger-e74d7d998006.herokuapp.com/api';

export const registerUser = async (userData) => {
    const response = await axios.post(`${API_URL}/auth/register`, userData, {
        headers: {
            'Content-Type': 'application/json'
        }
    });
    return response.data;
};

export const authenticateUser = async (credentials) => {
    const response = await axios.post(`${API_URL}/auth/login`, credentials, {
        headers: {
            'Content-Type': 'application/json'
        }
    });
    return response.data;
};

export const refreshToken = async () => {
    // Implement refresh token logic here if needed
};
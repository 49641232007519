import React from 'react';
//import ProjectorList from '../components/projectors/ProjectorList';

const ProjectorPage = () => {
    return (
        <div>
            <h1>Projector Page</h1>
            <p>Welcome to the Projector page!</p>
        </div>
    );
};

export default ProjectorPage;

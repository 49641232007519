import React, { useEffect, useState } from 'react';
import axios from 'axios';

const UserProfile = () => {
    const [profile, setProfile] = useState(null);
    const [error, setError] = useState('');

    useEffect(() => {
        const fetchProfile = async () => {
            try {
                console.log('Fetching user profile...');
                const response = await axios.get('/api/profile', {
                    headers: {
                        Authorization: `Bearer ${localStorage.getItem('token')}`,
                    },
                });
                console.log('User profile fetched:', response.data);
                setProfile(response.data);
            } catch (err) {
                console.error('Failed to fetch profile:', err);
                setError('Failed to fetch profile');
            }
        };

        fetchProfile();
    }, []);

    if (error) {
        return <div>{error}</div>;
    }

    if (!profile) {
        return <div>Loading...</div>;
    }

    return (
        <div>
            <h1>Profile</h1>
            <p>Username: {profile.username}</p>
            <p>Email: {profile.email}</p>
            {/* Add more fields based on your user entity */}
        </div>
    );
};

export default UserProfile;

import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import ItemCard from '../../ItemCard';
import { getAllLenses } from '../../services/lensService';

const LensList = () => {
    const [lenses, setLenses] = useState([]);

    useEffect(() => {
        async function fetchLenses() {
            try {
                const data = await getAllLenses();
                setLenses(data);
            } catch (error) {
                console.error('Error fetching lenses:', error);
            }
        }
        fetchLenses();
    }, []);

    return (
        <div>
            <h1>Lens List</h1>
            <div className="item-list">
                {lenses.map((lens) => (
                    <Link to={`/lenses/${lens.id}`} key={lens.id}>
                        <ItemCard item={lens} />
                    </Link>
                ))}
            </div>
        </div>
    );
};

export default LensList;

import axios from 'axios';

const API_URL = process.env.REACT_APP_API_URL || 'https://rent-ranger-e74d7d998006.herokuapp.com/api/lightings';

export const getAllLightings = async () => {
    const response = await axios.get(API_URL);
    return response.data;
};

export const getLightingById = async (id) => {
    const response = await axios.get(`${API_URL}/${id}`);
    return response.data;
};

export const addLighting = async (lighting) => {
    const response = await axios.post(API_URL, lighting);
    return response.data;
};

export const updateLighting = async (id, lighting) => {
    const response = await axios.put(`${API_URL}/${id}`, lighting);
    return response.data;
};

export const deleteLighting = async (id) => {
    const response = await axios.delete(`${API_URL}/${id}`);
    return response.data;
};

export const getAvailableLightings = async () => {
    const response = await axios.get(`${API_URL}/available`);
    return response.data;
};
